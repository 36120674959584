








































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import TextAreaWithOneModeText from '@/components/TextAreaWithOneModeText.vue'
import {
  GroupDetail,
  ENameColor,
  EAvatarType,
  EEditMode,
  ETinyFileType
} from '@/models'
import scrollToError from '@/helpers/ScrollToError'
import GroupService from '@/services/GroupService'
import { EhumbNailsDisplay } from '@/models/Setting/Enum'
//@ts-ignore
import _ from 'lodash'
import updateGroupMaxFileSize from '@/helpers/UpdateGroupMaxFileSize'
import { Area } from '@/models/GroupTag/area'
import GroupTagService from '@/services/GroupTagService'
import { AxiosResponse } from 'axios'

/**
 * f2-101a
 * グループ設定　基本情報編集画面
 */
@Component({ components: { TextAreaWithOneModeText } })
export default class GroupInfoEditPage extends Vue {
  //data
  private groupInfo: GroupDetail = new GroupDetail()
  private groupInfoCurrent: GroupDetail = new GroupDetail() //used for check before leave
  private defaultAvaSrc: IAvatarSrc[] = []
  private defaultAvatarPick: IAvatarSrc = new IAvatarSrc()
  private avatarFullSizeSrc: string = ''
  private avatarFullSizeBlob!: Blob
  private avatarCropperSrc: string = ''
  private chooseAvatarName: string = String(this.$t('common.form.not_selected'))
  private chooseAvatarTempName: string = ''
  private coverSrc: string = ''
  private coverBlob!: File
  private chooseCoverName: string = String(this.$t('common.form.not_selected'))
  private noticeMode: EEditMode = EEditMode.TEXT
  private noticeText: string = ''
  private noGroupAvatar =
    window.location.origin + require('@/assets/images/group/avatar_default_1.png')

  //validate
  private modalMess: string = ''
  private imageType: string[] = ['image/png', 'image/gif', 'image/jpeg']
  private errorAvatarTypeAndSize: boolean = false
  private errorCoverTypeAndSize: boolean = false
  private maxFileSize: any = {
    mb: Number(this.$store.state.userInfo.user.current_group_max_file_size),
    bytes:
      Number(this.$store.state.userInfo.user.current_group_max_file_size) *
      1048576
  }
  private validNotice: boolean = true

  //enum
  private eNameColor: any = ENameColor
  private eAvatarType: any = EAvatarType
  private eTinyFileType: any = ETinyFileType
  private ehumbNailsDisplay: any = EhumbNailsDisplay

  //hanlde before leave
  private confirmLeave: any = () => {}
  private confirmLeaveIgnore: boolean = false
  private groupTagArea: Area[] = []
  private listArea: any[] = []

  public topic: any = {
    nationwide_id: [],
    province_id: [],
    district_id: [],
    // check_group_all: false,
    group_tag_id: [],
  }
  private selectedValueTargetArea: any[] = []
  private check_group_all: any = 0
  private check_all: any = ''
  private groupTagAreaId: any = []
  private nationwideAreaId: any = []
  private provinceAreaId: any = []
  private districtAreaId: any = []
  private selectAreaAll: any = []
  private listAreaProvince: any[] = []
  private listAreaDistrict: any[] = []
  private listGroupTagArea: any[] = []
  private listNation: any[] = []
  private listProvince: any[] = []
  private listDistrict: any[] = []

  async created() {
    this.importAllDefaultAvatar()
    this.getGroupInfoDetail(this.$route.params.groupId)
    this.setDefaultForAvatar()
    this.handleMaxFileSize()
  }

  @Watch('check_all')
  watchAll() { 
    console.log(this.check_all);
    
    if (this.check_all) {
      this.check_group_all = 1
    } else {
      this.check_group_all = 0
    }

    // if (this.check_all === 1) {
    //   this.check_group_all = 1
    //   this.topic.group_tag_id = this.listGroupTagArea
    //   this.topic.nationwide_id = this.listNation
    //   this.topic.province_id = this.listProvince
    //   this.topic.district_id = this.listDistrict
    // } else {
    //   this.check_group_all = 0
    // }
  }

  // checkGroupAll(e: any) { 
  //   this.check_group_all = e.target.checked
  //   if (this.check_group_all) {
  //     var listGroupTagArea = this.groupTagArea.map((groupTagArea) => (groupTagArea.id))
  //     var listArea = this.listArea.map((listArea) => (listArea.id))
  //     this.selectedValueTargetArea = listGroupTagArea
  //     this.topic.nationwide_id = listArea
  //     this.check_all = 1
  //   } else {
  //     this.selectedValueTargetArea = []
  //     this.topic.nationwide_id = []
  //     this.check_all = 0
  //   }
  // }

  @Watch('topic.group_tag_id')
  watchSelectedValueTargetArea() {
    if (this.topic.group_tag_id.length !== this.groupTagArea.map((groupTagArea) => (groupTagArea.id)).length) {
      this.check_all = false
      this.check_group_all = 0
    } else {
      if (this.topic.district_id.length !== this.listAreaDistrict.map((listAreaDistrict) => (listAreaDistrict.id)).length) {
        this.check_all = false
        this.check_group_all = 0
      } else {
        if (this.topic.province_id.length !== this.listAreaProvince.map((listAreaProvince) => (listAreaProvince.id)).length) {
          this.check_all = false
          this.check_group_all = 0
        } else {
          if (this.topic.nationwide_id.length !== this.listNation.map((listNation) => (listNation.id)).length) {
            this.check_all = false
            this.check_group_all = 0
          } else {
            this.check_all = true
            this.check_group_all = 1
          }
          this.check_all = true
          this.check_group_all = 1
        }
        this.check_all = true
        this.check_group_all = 1
      }
    }
  }

  @Watch('topic.province_id')
  watchProvinceArea() {
    if (this.topic.province_id.length !== this.listAreaProvince.map((listAreaProvince) => (listAreaProvince.id)).length) {
      this.check_all = false
      this.check_group_all = 0
    } else {
      if (this.topic.district_id.length !== this.listAreaDistrict.map((listAreaDistrict) => (listAreaDistrict.id)).length) {
        this.check_all = false
        this.check_group_all = 0
      } else {
        if (this.topic.group_tag_id.length !== this.groupTagArea.map((groupTagArea) => (groupTagArea.id)).length) {
          this.check_all = false
          this.check_group_all = 0
        } else {
          if (this.topic.nationwide_id.length !== this.listNation.map((listNation) => (listNation.id)).length) {
            this.check_all = false
            this.check_group_all = 0
          } else {
            this.check_all = true
            this.check_group_all = 1
          }
          this.check_all = true
          this.check_group_all = 1
        }
        this.check_all = true
        this.check_group_all = 1
      }
    }
  }

  @Watch('topic.district_id')
  watchDistrictArea() {
    if (this.topic.district_id.length !== this.listAreaDistrict.map((listAreaDistrict) => (listAreaDistrict.id)).length) {
      this.check_all = false
      this.check_group_all = 0
    } else {
      if (this.topic.group_tag_id.length !== this.groupTagArea.map((groupTagArea) => (groupTagArea.id)).length) {
        this.check_all = false
        this.check_group_all = 0
      } else {
        if (this.topic.province_id.length !== this.listAreaProvince.map((listAreaProvince) => (listAreaProvince.id)).length) {
          console.log('one');
          
          this.check_all = false
          this.check_group_all = 0
        } else {
          if (this.topic.nationwide_id.length !== this.listNation.map((listNation) => (listNation.id)).length) {
            this.check_all = false
            this.check_group_all = 0
          } else {
            this.check_all = true
            this.check_group_all = 1
          }
          this.check_all = true
          this.check_group_all = 1
        }
        this.check_all = true
        this.check_group_all = 1
      }
    }
  }

  // checkboxValueArea($id: string) {
  //   const [top1, top2, top3] = $id.split("_");

  //   if (Number(top3)) {
  //     Object.entries(this.$refs).forEach(([key, value]) => {
  //       if (key.includes(`${top1}_${top2}_0`) || key.includes(`${top1}_0_0`)) {
  //         if ((value as any)[0]) {
  //           (value as any)[0].checked = true;

  //           if (
  //             (value as any)[0].getAttribute("data-checked-nationwide") &&
  //             !this.topic.nationwide_id.includes((value as any)[0].value)
  //           ) {
  //             this.topic.nationwide_id.push((value as any)[0].value);
  //           }

  //           if (
  //             (value as any)[0].getAttribute("data-checked-province") &&
  //             !this.topic.province_id.includes((value as any)[0].value)
  //           ) {
  //             this.topic.province_id.push((value as any)[0].value);
  //           }
  //         }
  //       }
  //     });
  //   } else if (Number(top2)) {
  //     Object.entries(this.$refs).forEach(([key, value]) => {
  //       if (key.includes(`${top1}_0_0`)) {
  //         if ((value as any)[0]) {
  //           (value as any)[0].checked = true;

  //           if (
  //             (value as any)[0].getAttribute("data-checked-nationwide") &&
  //             !this.topic.nationwide_id.includes((value as any)[0].value)
  //           ) {
  //             this.topic.nationwide_id.push((value as any)[0].value);
  //           }
  //         }
  //       }

  //       if (key !== `${top1}_${top2}_0` && key.includes(`${top1}_${top2}_`)) {
  //         if ((value as any)[0]) {
  //           (value as any)[0].checked = false;

  //           if (
  //             (value as any)[0].getAttribute("data-checked-district") &&
  //             this.topic.district_id.length
  //           ) {
  //             this.topic.district_id = this.topic.district_id.filter(function (
  //               item: any
  //             ) {
  //               return item != (value as any)[0].value;
  //             });
  //           }
  //         }
  //       }
  //     });
  //   } else {
  //     Object.entries(this.$refs).forEach(([key, value]) => {
  //       if (key !== `${top1}_0_0` && key.includes(`${top1}_`)) {
  //         if ((value as any)[0]) {
  //           (value as any)[0].checked = false;
  //         }

  //         if (
  //           (value as any)[0].getAttribute("data-checked-district") &&
  //           this.topic.district_id.length
  //         ) {
  //           this.topic.district_id = this.topic.district_id.filter(function (
  //             item: any
  //           ) {
  //             return item != (value as any)[0].value;
  //           });
  //         }

  //         if (
  //           (value as any)[0].getAttribute("data-checked-province") &&
  //           this.topic.province_id.length
  //         ) {
  //           this.topic.province_id = this.topic.province_id.filter(function (
  //             item: any
  //           ) {
  //             return item != (value as any)[0].value;
  //           });
  //         }
  //       }
  //     });
  //   }
  // }

  toggleSelect(event: any) {
    if(event.target.checked) {
      Object.entries(this.$refs).forEach(([key, value]) => {
        if ((value as any)[0]) {
          (value as any)[0].checked = true;

          if (
              (value as any)[0].getAttribute("data-checked-group-tag") &&
              !this.topic.group_tag_id.includes((value as any)[0].value)
            ) {
              this.topic.group_tag_id.push((value as any)[0].value);
            }

          if (
              (value as any)[0].getAttribute("data-checked-nationwide") &&
              !this.topic.nationwide_id.includes((value as any)[0].value)
            ) {
              this.topic.nationwide_id.push((value as any)[0].value);
            }

          if (
            (value as any)[0].getAttribute("data-checked-province") &&
            !this.topic.province_id.includes((value as any)[0].value)
          ) {
            this.topic.province_id.push((value as any)[0].value);
          }

          if (
            (value as any)[0].getAttribute("data-checked-district") &&
            !this.topic.district_id.includes((value as any)[0].value)
          ) {
            this.topic.district_id.push((value as any)[0].value);
          }
        }
      })
    } else {
      Object.entries(this.$refs).forEach(([key, value]) => {
        if ((value as any)[0]) {
          (value as any)[0].checked = false;
        }

        this.topic.group_tag_id = []
        this.topic.nationwide_id = []
        this.topic.province_id = []
        this.topic.district_id = []
      })
    }
  }

  checkboxValueArea($id: string) {
    const [top1, top2, top3] = $id.split("_");

    if (Number(top3)) {
      Object.entries(this.$refs).forEach(([key, value]) => {
        if (key.includes(`${top1}_${top2}_0`) || key.includes(`${top1}_0_0`)) {
          if ((value as any)[0]) {
            (value as any)[0].checked = true;

            if (
              (value as any)[0].getAttribute("data-checked-nationwide") &&
              !this.topic.nationwide_id.includes((value as any)[0].value)
            ) {
              this.topic.nationwide_id.push((value as any)[0].value);
            }

            if (
              (value as any)[0].getAttribute("data-checked-province") &&
              !this.topic.province_id.includes((value as any)[0].value)
            ) {
              this.topic.province_id.push((value as any)[0].value);
            }

          }
        }
        
        if (key == "CHECK_ALL") {
          (value as any).checked = false;
        }

      });
    } else if (Number(top2)) {
      Object.entries(this.$refs).forEach(([key, value]) => {
        if (key.includes(`${top1}_0_0`)) {
          if ((value as any)[0]) {
            (value as any)[0].checked = true;

            if (
              (value as any)[0].getAttribute("data-checked-nationwide") &&
              !this.topic.nationwide_id.includes((value as any)[0].value)
            ) {
              this.topic.nationwide_id.push((value as any)[0].value);
            }
          }
        }

        if (key !== `${top1}_${top2}_0` && key.includes(`${top1}_${top2}_`) || key == "CHECK_ALL") {
          if (key == "CHECK_ALL") {
            (value as any).checked = false;
          }

          if (key != "CHECK_ALL" && (value as any)[0]) {
            (value as any)[0].checked = false;

            if (
              key != "CHECK_ALL" &&
              (value as any)[0].getAttribute("data-checked-district") &&
              this.topic.district_id.length
            ) {
              this.topic.district_id = this.topic.district_id.filter(function (
                item: any
              ) {
                return item != (value as any)[0].value;
              });
            }
          }
        }
      });
    } else {
      Object.entries(this.$refs).forEach(([key, value]) => {
        if (key !== `${top1}_0_0` && key.includes(`${top1}_`) || key == "CHECK_ALL") {
          if (key == "CHECK_ALL") {
            (value as any).checked = false;
          }

          if (key != "CHECK_ALL" && (value as any)[0]) {
            (value as any)[0].checked = false;
          }

          if (
            key != "CHECK_ALL" &&
            (value as any)[0].getAttribute("data-checked-district") &&
            this.topic.district_id.length
          ) {
            this.topic.district_id = this.topic.district_id.filter(function (
              item: any
            ) {
              return item != (value as any)[0].value;
            });
          }

          if (
            key != "CHECK_ALL" &&
            (value as any)[0].getAttribute("data-checked-province") &&
            this.topic.province_id.length
          ) {
            this.topic.province_id = this.topic.province_id.filter(function (
              item: any
            ) {
              return item != (value as any)[0].value;
            });
          }
        }
      });
    }

  }

  async handleMaxFileSize() {
    await updateGroupMaxFileSize(this.$route.params.groupId)
    this.maxFileSize = {
      mb: Number(this.$store.state.userInfo.user.current_group_max_file_size),
      bytes:
        Number(this.$store.state.userInfo.user.current_group_max_file_size) *
        1048576
    }
  }

  checkRouterBeforLeave() {
    return (
      this.groupInfo.name === this.groupInfoCurrent.name &&
      this.groupInfo.notice === this.groupInfoCurrent.notice &&
      this.groupInfo.letter_color === this.groupInfoCurrent.letter_color &&
      this.groupInfo.icon_image === this.groupInfoCurrent.icon_image &&
      this.groupInfo.auto_delete_day ===
        this.groupInfoCurrent.auto_delete_day &&
      !this.coverBlob &&
      !this.avatarFullSizeBlob
    )
  }

  beforeRouteLeave(to: any, from: any, next: any) {
    //this case for modal search header
    if (document.querySelectorAll('#modal-search-header').length) {
      next()

      //normal case
    } else if (!this.confirmLeaveIgnore && !this.checkRouterBeforLeave()) {
      this.confirmLeave = next
      this.$bvModal.show('group-edit-modal-confirm-leave')
    } else {
      next()
    }
  }

  @Watch('avatarFullSizeSrc')
  setDefaultForAvatar() {
    if (!this.avatarFullSizeSrc) {
      this.avatarFullSizeSrc = this.noGroupAvatar
    }
  }

  @Watch('groupInfo.icon_image')
  watchUseageAvatar() {
    if (this.groupInfo.icon_image === EAvatarType.NONE) {
      this.avatarFullSizeSrc = ''
      this.chooseAvatarName = ''
    } else {
      this.avatarFullSizeSrc = this.groupInfo.icon_image_small_path
      this.chooseAvatarName = this.groupInfo.icon_file_name
    }
  }

  importAllDefaultAvatar() {
    GroupService.getGroupDefaultIcon().then(res => {
      if (res.status === 200) {
        this.defaultAvaSrc = res.data
      }
    })
  }

  selectDefaultAvatar(pickedAvatar: IAvatarSrc) {
    this.avatarFullSizeSrc = pickedAvatar.small_path
    this.defaultAvatarPick = pickedAvatar
  }

  private handleResponseForListTagArea(response: AxiosResponse<any>) {
    this.listArea = response.data.nation;
    this.groupTagArea = response.data.area;
    this.listAreaProvince = response.data.province
    this.listAreaDistrict = response.data.district
    this.listGroupTagArea = this.groupTagArea.map((groupTagArea) => (groupTagArea.id))
    this.listNation = this.listArea.map((listArea) => (listArea.id))
    this.listProvince = this.listAreaProvince.map((listAreaProvince) => (listAreaProvince.id))
    this.listDistrict = this.listAreaDistrict.map((listAreaDistrict) => (listAreaDistrict.id)) 
  }

  private handleResponseForGroupInfoDetail(res: AxiosResponse<any>) {
    this.groupInfo = new GroupDetail(res.data)
    this.groupInfoCurrent = _.cloneDeep(this.groupInfo)
    this.avatarFullSizeSrc = this.groupInfo.icon_image_small_path
    this.coverSrc = this.groupInfo.header_image
    this.noticeMode = Number(this.groupInfo.notice?.startsWith('<'))
    this.chooseCoverName = this.groupInfo.header_file_name
    this.chooseAvatarName = this.groupInfo.icon_file_name

    if (this.noticeMode === EEditMode.TEXT) {
      this.noticeText = this.groupInfo.notice
    }

    this.topic.group_tag_id = res.data.group_group_area_tag.map((groupTagAreaId: any) => (groupTagAreaId.group_tag_id));
    this.topic.nationwide_id = res.data.group_group_tag_nationwide.map((nationwideAreaId: any) => (nationwideAreaId.nationwide_id))
    this.topic.province_id = res.data.group_group_tag_province.map((provinceAreaId: any) => (provinceAreaId.province_id))
    this.topic.district_id = res.data.group_group_tag_district.map((districtAreaId: any) => (districtAreaId.district_id))
    
    if (res.data.group_group_tag_area_all.length > 0) {
      this.check_all = true
      this.topic.group_tag_id = this.listGroupTagArea
      this.topic.nationwide_id = this.listNation
      this.topic.province_id = this.listProvince
      this.topic.district_id = this.listDistrict
      // this.topic.group_tag_id = res.data.area.map((area:any) => (area.id));
      // this.topic.nationwide_id = res.data.nation.map((nation:any) => (nation.id))
    }
  }

  getGroupInfoDetail(groupId: string) {
    Promise.all([GroupTagService.getListTagArea(), GroupService.getGroupById(groupId) ])
      .then(([response, res]) => {
        if (response.status === 200) {
          this.handleResponseForListTagArea(response)
        }
        if (res.status === 200) {
          this.handleResponseForGroupInfoDetail(res);
        }
      })
      .catch(err => {
        console.log(err);
        
        this.$bvModal.show('modal-error-group-edit')
      })
  }

  onAvatarChange(event: any) {
    let files = event.target.files || event.dataTransfer.files
    if (event.target.files.length !== 1) return

    //error file size
    if (files[0].size > this.maxFileSize.bytes) {
      this.modalMess = this.$t('groups.shared_folder.overwrite_error_msg', {
        max: this.maxFileSize.mb
      }) as string
      this.$bvModal.show('modal-info-group-edit')
      this.errorAvatarTypeAndSize = true
      return
    }

    //error file type
    if (!this.imageType.includes(files[0].type)) {
      this.modalMess = this.$t('common.message.upload_image_only') as string
      this.$bvModal.show('modal-info-group-edit')
      this.errorAvatarTypeAndSize = true
      return
    }

    //normal case
    this.errorAvatarTypeAndSize = false
    const blob = new Blob([files[0]])
    this.avatarCropperSrc = URL.createObjectURL(blob)
    this.chooseAvatarTempName = files[0].name
    this.$bvModal.show('cropAvatar')
  }

  onCoverChange(event: any) {
    let files = event.target.files || event.dataTransfer.files
    if (event.target.files.length !== 1) return

    //error file size
    if (files[0].size > this.maxFileSize.bytes) {
      this.modalMess = this.$t('groups.shared_folder.overwrite_error_msg', {
        max: this.maxFileSize.mb
      }) as string
      this.$bvModal.show('modal-info-group-edit')
      this.errorCoverTypeAndSize = true
      return
    }

    //error file type
    if (!this.imageType.includes(files[0].type)) {
      this.modalMess = this.$t('common.message.upload_image_only') as string
      this.$bvModal.show('modal-info-group-edit')
      this.errorCoverTypeAndSize = true
      return
    }

    //normal case
    this.errorCoverTypeAndSize = false
    const blob = new Blob([files[0]])
    this.coverSrc = URL.createObjectURL(blob)
    this.coverBlob = files[0]
    this.chooseCoverName = files[0].name
  }

  handleCropperImg() {
    const canvas = (this.$refs.avatarCropper as any).clip()
    canvas.toBlob((blob: Blob) => {
      this.avatarFullSizeSrc = URL.createObjectURL(blob)
      this.avatarFullSizeBlob = blob
      this.chooseAvatarName = this.chooseAvatarTempName
      this.$bvModal.hide('cropAvatar')
    })
  }

  handleValueNotice(value: any) {
    if (value.mode === EEditMode.TEXT) {
      this.groupInfo.notice = value.text
    } else {
      this.groupInfo.notice = value.html
    }
    this.noticeText = value.text
  }

  handleValidNotice(valid: boolean) {
    this.validNotice = valid
  }

  async onSubmitGroupInfo(valid: boolean) {
    console.log('this.check_all', this.check_all);
    console.log('this.check_group_all', this.check_group_all);
    if (
      valid &&
      !this.errorAvatarTypeAndSize &&
      !this.errorCoverTypeAndSize &&
      this.validNotice
    ) {
      this.confirmLeaveIgnore = true
      let formData = new FormData()
      formData.set('id', String(this.groupInfo.id))
      formData.set('name', this.groupInfo.name)
      formData.set('icon_image', String(this.groupInfo.icon_image))
      formData.set('icon_file_name', this.chooseAvatarName)
      formData.set('letter_color', String(this.groupInfo.letter_color))
      formData.set('notice', this.groupInfo.notice)
      formData.set('auto_delete_day', String(this.groupInfo.auto_delete_day))
      if (this.coverBlob) {
        formData.set('header_image_blob', this.coverBlob)
        formData.set('header_file_name', this.chooseCoverName)
      }
      if (this.check_all) {
        formData.set('area_all', this.check_group_all)
      } else {
        formData.set('target_area', String(this.topic.group_tag_id))
        formData.set('nationwide_id', String(this.topic.nationwide_id))
        formData.set('province_id', String(this.topic.province_id))
        formData.set('district_id', String(this.topic.district_id))
      }
      this.handleIconImageChosse(formData)
      formData.append('_method', 'PATCH')
      this.$blockui.show()
      GroupService.updateGroupBasicInfo(formData)
        .then(res => {
          if (res.status === 200) {
            this.modalMess = this.$t('common.message.registered') as string
            this.$bvModal.show('modal-success')
          }
        })
        .catch(() => {
          this.modalMess = this.$t('common.message.register_fail') as string
          this.$bvModal.show('modal-error')
        })
        .finally(() => this.$blockui.hide())
    } else {
      scrollToError(this.$refs, 'groupBasicInfo')
    }
  }

  /**
   * Hanle group icon
   */
  handleIconImageChosse(formData: FormData) {
    switch (this.groupInfo.icon_image) {
      case EAvatarType.FILE_UPLOAD:
        if (this.avatarFullSizeBlob)
          formData.set('icon_image_blob', this.avatarFullSizeBlob)
        break
      case EAvatarType.DEFAULT:
        if (this.groupInfo.icon_image_small_path !== this.avatarFullSizeSrc) {
          formData.set('icon_image_path', this.defaultAvatarPick.path)
          formData.set(
            'icon_image_small_path',
            this.defaultAvatarPick.small_path
          )
        }
        break
      case EAvatarType.NONE:
        formData.set('icon_image_path', this.noGroupAvatar)
        formData.set('icon_image_small_path', this.noGroupAvatar)
        break
      default:
        break
    }
  }

  backToInfoPage() {
    this.$router.push({ name: 'group-setting-information' })
  }

  backToInfoPageRoute() {
    return ({ name: 'group-setting-information' })
  }
}

class IAvatarSrc {
  id!: number
  name: string = ''
  path: string = ''
  small_path: string = ''

  constructor(init?: Partial<IAvatarSrc>) {
    Object.assign(this, init)
  }
}
